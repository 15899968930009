import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, timer } from 'rxjs';
import { concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import {
  SellerListingService,
  UsersService,
  ChatKittyService,
  LoadingService,
  IUsalliance,
  UsallianceService,
  PlaidService,
  NotificationService,
  OnboardingService,
} from '../core/services';

import { ISellerListing, IUser, ISellerListingStatuses } from '../models';
import { NavbarService } from '../core/services/navbar.service';
import { APP_CONFIG, IAppConfig } from '../config/config';
import { PosthogService } from '../core/services/posthog.service';
import { ProveVerificationModal } from '../prove-verification/prove-verification-modal/prove-verification-modal.component';
import { ProgressBarStepsItem } from '../widgets/progress-bar-steps/progress-bar-steps.component';
import { BusinessVerificationModal } from '../prove-verification/business-verification-modal/business-verification-modal.component';
import { AuthorizationService, AuthorizedAction, AuthorizedSubject } from '../core/services/authorizaton.service';
import { VerificationService } from '../core/services/verification.service';
import { FinancialAccountService } from '../core/services/financial-account.service';
import { IBalance, UnpaidListings } from '../models/financial-account.model';
import { OrganizationVerificationStatus } from '../models/organization.model';

const navBarHiddenRoutes = ['/verification-completed'];
declare let Beacon;
declare let hj: any;

interface NavMenuItem {
  icon: string;
  text: string;
  link: string;
  notifications: Promise<any> | null | number;
  selected: boolean;
  error?: boolean;
  addNew?: boolean;
  mobileViewIcon?: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild('listingBox') listingBox: ElementRef;
  @ViewChild('footer') footer: ElementRef;
  @ViewChild('scrollNav') scrollNav: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('verificationModal') proveVerificationModal: ProveVerificationModal;
  @ViewChild('businessVerificationModal') businessVerificationModal: BusinessVerificationModal;

  @Output() onLogout = new EventEmitter<any>();

  menu: NavMenuItem[];

  businessAccountMenu: NavMenuItem[];

  bottomMenu: NavMenuItem[];
  mobileButtonMenus: NavMenuItem[];

  // account: IUsalliance['account'];
  listings: ISellerListing[] = [];
  showNav = false;
  user: IUser;
  isUserVerified = false;
  isUserPartiallyVerified = false;
  isMenuCollapsed = true;
  isGlobalLoading = false;
  listSelected = false;
  hasInvalidBank = false;
  financeDisconnected = false;
  isMobileView = false;
  currentUrl = '';
  chatConnected = true;
  user$: Subscription;
  accountRecentlyDeleted = false;

  showSellVehicleBox = true;

  showGetFullyVerifiedPopover = false;
  isGetFullyVerifiedPopoverAlreadyShown = false;
  showEnterDisplayName = false;

  footerYPosition = 0;
  screenHeight = 0;
  scrolled = false;

  showFailedPayLaterModal = false;
  isFailedPayLaterAlreadyShown = false;

  showProveVerificationModal = false;
  showWelcomeBusinessVerificationModal = false;
  showBusinessVerificationModal = false;
  showBusinessInformationModal = false;
  showInviteBusinessOwnerModal = false;

  failedPayLaterListings: IUsalliance['account']['unpaidListings'];

  getCurrentProfile$: Subscription;
  listings$: Subscription;
  setUser$: Subscription;
  websiteUrl: string = this.config.websiteUrl;
  proveVerificationEnabled = false;
  proveVerificationSkipped = false;

  showBottomNav = false;
  chatNotification = false;

  steps: ProgressBarStepsItem[] = [];
  isMarketPlaceEnabled: boolean;
  balance: IBalance;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly usersService: UsersService,
    private readonly sellerListingService: SellerListingService,
    private readonly router: Router,
    private readonly chatKittyService: ChatKittyService,
    private readonly loadingService: LoadingService,
    private readonly usallianceService: UsallianceService,
    public readonly navbarService: NavbarService,
    private readonly plaidService: PlaidService,
    private readonly notificationService: NotificationService,
    private renderer: Renderer2,
    private readonly posthogService: PosthogService,
    private readonly onboardingService: OnboardingService,
    private readonly authorizationService: AuthorizationService,
    private readonly verificationService: VerificationService,
    private readonly financialAccountService: FinancialAccountService,
  ) {
    this.isMarketPlaceEnabled = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.BrowseListings);
  }

  ngOnInit(): void {
    this.financialAccountService.balance$.subscribe((balance) => {
      this.balance = balance;
    });
    this.chatKittyService.onCountedUnread = (count) => {
      this.menu.find((item) => item.text === 'Chat').notifications = count;
      this.chatNotification = !!count;
    };
    this.navbarService.visibilityChanged$.subscribe((visible) => {
      this.showNav = visible;
    });
    this.navbarService.isMobileChanged$.subscribe((value) => {
      this.isMobileView = value;
    });
    this.usallianceService.accountRecentlyDeleted.subscribe((results) => {
      this.accountRecentlyDeleted = results;
    });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap((e: NavigationEnd) => {
          return this.usersService.user.pipe(map((user) => ({ user, e })));
        }),
      )
      .subscribe(({ e, user }) => {
        this.currentUrl = e.url;
        this.isMenuCollapsed = true;
        if (!user || this.shouldHideNav()) {
          this.navbarService.hide();
        } else {
          this.navbarService.show();
        }
        const queryParamsIndex = this.currentUrl.indexOf('?');
        if (queryParamsIndex >= 0) {
          const queryParamsString = this.currentUrl.substring(queryParamsIndex + 1);
          const queryParams = new URLSearchParams(queryParamsString);

          const initialReferrer = queryParams.get('initialReferrer');
          if (initialReferrer) {
            this.usersService.updateInitialReferrer(initialReferrer).subscribe(() => {});
          }
        }
        this.menu = [
          this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ManageListings) &&
            this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.listing) && {
              icon: 'assets/icons/garage.svg',
              text: 'My garage',
              notifications: null,
              link: '/garage',
              selected: false,
              addNew: true,
            },
          this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.BrowseListings) && {
            icon: 'assets/search.svg',
            text: 'Marketplace',
            notifications: null,
            link: '/search-listings',
            selected: false,
          },
          {
            icon: 'assets/servicesNav.svg',
            text: 'Services',
            notifications: null,
            link: '/services',
            selected: false,
          },
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.chat) && {
            icon: 'assets/message-circle.svg',
            text: 'Chat',
            notifications: 0,
            link: '/inbox',
            selected: false,
            error: false,
          },
          {
            icon: 'assets/calendar.svg',
            text: 'Test Drives',
            notifications: null,
            link: '/test-drives',
            selected: false,
          },
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.deal) && {
            icon: 'assets/award.svg',
            text: 'Deals',
            notifications: null,
            link: '/deals',
            selected: false,
          },
        ].filter(Boolean);

        this.businessAccountMenu = [
          {
            icon: '/assets/icons/business-icon.svg',
            mobileViewIcon: '/assets/icons/business-icon-black.svg',
            link: '/business-account',
            text: 'Business account',
            notifications: null,
            selected: false,
            addNew: false,
          },
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.user) && {
            icon: '/assets/icons/add-users.svg',
            mobileViewIcon: '/assets/icons/add-users-black.svg',
            link: '/business-account/members',
            text: 'Add members',
            notifications: null,
            selected: false,
            addNew: false,
          },
        ].filter(Boolean);

        this.bottomMenu = [
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.banking) && {
            icon: 'assets/icons/nav-money.svg',
            text: 'My money',
            notifications: null,
            link: this.user.verification.isVerified ? '/account/money' : '/account/money/set-up',
            selected: false,
            addNew: false,
          },
          this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ManageListings) &&
            this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.listing) && {
              icon: 'assets/icons/nav-garage.svg',
              text: 'My garage',
              notifications: null,
              link: '/garage',
              selected: false,
              addNew: true,
            },
          {
            icon: 'assets/icons/nav-calendar.svg',
            text: 'Test Drives',
            notifications: null,
            link: '/test-drives',
            selected: false,
          },
        ].filter(Boolean);

        this.mobileButtonMenus = [
          this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.BrowseListings) && {
            link: '/search-listings',
            icon: '/assets/icons/nav-search.svg',
            text: 'Marketplace',
            notifications: null,
            selected: false,
            addNew: false,
          },
          {
            link: '/services',
            icon: '/assets/icons/nav-service.svg',
            text: 'Services',
            notifications: null,
            selected: false,
            addNew: false,
          },
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.deal) && {
            link: '/deals',
            icon: '/assets/icons/nav-deal.svg',
            text: 'Deals',
            notifications: null,
            selected: false,
            addNew: false,
          },
          this.authorizationService.can(AuthorizedAction.manage, AuthorizedSubject.chat) && {
            link: '/inbox',
            icon: '/assets/icons/nav-chat.svg',
            text: 'Chat',
            notifications: 1,
            selected: false,
            addNew: false,
          },
        ].filter(Boolean);
      });

    this.proveVerificationEnabled = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ProveVerification);
    this.showBottomNav = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.BottomNavShow);
    this.setUser();
    this.getCurrentProfile();

    this.listings$ = this.sellerListingService.sellerListings
      .pipe(
        map((listings) =>
          listings.filter((listing) => listing.status !== ISellerListingStatuses.Sold && listing.diff120 > -10),
        ),
      )
      .subscribe((listings: ISellerListing[]) => {
        this.listings = listings;
      });

    timer(1000).subscribe(() => this.modifyLinksToToggleNav());

    this.loadingService.isLoading$.subscribe((isLoading) => {
      this.isGlobalLoading = isLoading;
    });

    this.usersService.user
      .pipe(
        switchMap((user) => {
          if (user.organizationId) {
            // New financial provider case
            return this.plaidService.accounts.pipe(
              switchMap((plaid) => {
                return this.financialAccountService.account$.pipe(
                  map((financialAccount) => ({
                    plaid,
                    isLegacyProvider: false,
                    usalliance: null,
                    financialAccount,
                  })),
                );
              }),
            );
          } else {
            // Legacy banking provider (UsAlliance) case
            return this.plaidService.accounts.pipe(
              switchMap((plaid) => {
                return this.usallianceService.account.pipe(
                  map((usalliance) => ({
                    plaid,
                    isLegacyProvider: true,
                    usalliance,
                    financialAccount: null,
                  })),
                );
              }),
            );
          }
        }),
      )
      .subscribe(({ plaid, isLegacyProvider, usalliance, financialAccount }) => {
        this.hasInvalidBank = plaid?.accounts?.some((acc) => !acc?.plaid);

        if (isLegacyProvider) {
          this.financeDisconnected = !usalliance?.healthcheck || !plaid?.healthcheck;
          this.checkFailedPayLaterCanBeShown(usalliance?.unpaidListings);
        } else if (financialAccount) {
          this.checkFailedPayLaterCanBeShown(financialAccount?.unpaidListings);
          this.financeDisconnected = !plaid?.healthcheck;
        }
      });

    this.onboardingService.verifyModalIsVisible.subscribe((val) => {
      this.showProveVerificationModal = val;
    });
    this.onboardingService.welcomeBusinessVerificationModalIsVisible.subscribe((val) => {
      this.showWelcomeBusinessVerificationModal = val;
    });
    this.onboardingService.verifyBusinessModalIsVisible.subscribe((val) => {
      this.showBusinessVerificationModal = val;
    });
    this.onboardingService.businessInformationModalIsVisible.subscribe((val) => {
      this.showBusinessInformationModal = val;
    });
    this.onboardingService.inviteBusinessOwnerModalIsVisible.subscribe((val) => {
      this.showInviteBusinessOwnerModal = val;
    });

    this.verificationService.verificationPopoverVisible.subscribe((val) => {
      this.showGetFullyVerifiedPopover = val;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Bootstrap's md devices below
    this.navbarService.setIsMobile(event.target.innerWidth <= 768);
  }

  ChangeListSelected(selected) {
    this.menu.forEach((item) => {
      item.selected = false;
      if (item.text === selected) {
        item.selected = !item.selected;
      }
    });
  }

  ngOnDestroy() {}

  getSteps(user: IUser): ProgressBarStepsItem[] {
    const { isEmailVerified, isUserPartiallyVerified, isVerified } = user.verification;
    const color = isVerified ? 'blue' : 'green';

    return [
      {
        icon: null,
        color,
        isDone: isEmailVerified,
      },
      {
        icon: !isVerified ? 'assets/verified.svg' : null,
        color,
        isDone: isUserPartiallyVerified,
      },
      {
        icon: 'assets/fully-verified.svg',
        color,
        isDone: isVerified,
      },
    ];
  }

  close() {
    this.showSellVehicleBox = false;
  }

  changeIsMenuCollapsed() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    if (this.isMenuCollapsed) {
      this.renderer.removeClass(this.document.body, 'stop-browser-scrolling');
    } else {
      this.renderer.addClass(this.document.body, 'stop-browser-scrolling');
    }
  }

  setUser() {
    this.setUser$ = this.usersService.user.subscribe((user) => {
      this.user = user;
      this.steps = this.getSteps(user);
      const { isEmailVerified, isPhoneVerified, isLicenseVerified, isVerified } = this.user.verification;
      this.isUserPartiallyVerified = isEmailVerified && isPhoneVerified && isLicenseVerified;
      this.isUserVerified = isVerified;
    });
  }

  getCurrentProfile() {
    this.usersService.user
      .pipe(
        take(1),
        concatMap((user) => {
          const { bankClosed, isClosedAutomatically } = user.bankInfo || {};
          if (bankClosed && isClosedAutomatically) {
            this.notificationService.notification(
              'info',
              `Your ${this.config.appName} Pay account has been closed due to inactivity.
        You can re-open it anytime by clicking the 'Verify' button next to ${this.config.appName} Pay.`,
            );
          }

          return this.usersService.user;
        }),
      )
      .subscribe((user) => {
        const userId = user._id;
        const email = user.userDetails.email;

        hj('identify', userId, {
          user_id: userId,
          email,
        });

        if (this.proveVerificationEnabled && !this.proveVerificationSkipped) {
          this.checkVerificationModal(user);
        } else {
          this.checkGetFullyVerifiedPopoverCanBeShown(user);
          this.checkIfUserHasDisplayName(user);
        }
      });
  }

  checkFailedPayLaterCanBeShown(unpaidListings: UnpaidListings) {
    if (this.isFailedPayLaterAlreadyShown) {
      return;
    }

    timer(100).subscribe(() => {
      this.showFailedPayLaterModal = !!unpaidListings?.deals?.length;
      this.failedPayLaterListings = unpaidListings;
      this.isFailedPayLaterAlreadyShown = !!this.showFailedPayLaterModal;
    });
  }

  checkVerificationModal(user: IUser) {
    if (this.shouldHideNav()) {
      return;
    }
    if (
      this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ProveKyb) &&
      (user?.isOrganizationCreator || user.isOrganizationOwner)
    ) {
      this.initBusinessAccountOnboarding(user);
    } else if (!user.verification.isLicenseVerified) {
      this.initProveVerificationModal(user);
    } else {
      this.checkGetFullyVerifiedPopoverCanBeShown(user);
      this.checkIfUserHasDisplayName(user);
    }
  }

  initProveVerificationModal(user: IUser) {
    this.proveVerificationModal.setInitialVerificationModalState(user);
  }

  initBusinessAccountOnboarding(user: IUser) {
    if (!user.organization?.name) {
      this.onboardingService.setBusinessInformationModalIsVisible(true);
    } else if (
      user.organization?.verificationStatus !== OrganizationVerificationStatus.Verified ||
      !user.organization?.ownerId
    ) {
      if (user.organization.verificationStatus === OrganizationVerificationStatus.Unverified) {
        this.onboardingService.setWelcomeBusinessVerificationModalIsVisible(true);
      } else {
        this.initBusinessAccountVerificationModal(user);
      }
    } else if (!user.verification.isLicenseVerified) {
      this.initProveVerificationModal(user);
    } else {
      this.checkGetFullyVerifiedPopoverCanBeShown(user);
      this.checkIfUserHasDisplayName(user);
    }
  }

  initBusinessAccountVerificationModal(user: IUser) {
    this.businessVerificationModal.setInitialVerificationModalState(user);
  }

  skipVerification() {
    this.proveVerificationSkipped = true;
    this.checkIfUserHasDisplayName(this.user);
  }

  checkIfUserHasDisplayName(user) {
    let shouldExecuteNextPendingAction = true;
    if (!user?.userDetails?.nickName) {
      const noInformation =
        !user?.userDetails?.firstName && !user?.userDetails?.lastName && !user.userDetails?.nickname;

      if (noInformation) {
        this.showEnterDisplayName = true;
        shouldExecuteNextPendingAction = false;
      }
    }
    if (shouldExecuteNextPendingAction) {
      this.onboardingService.executePendingHandler();
    }
  }

  checkGetFullyVerifiedPopoverCanBeShown(user: IUser) {
    if (this.isGetFullyVerifiedPopoverAlreadyShown) {
      return;
    }

    timer(100).subscribe(() => {
      const {
        buyerPageVisitsCount,
        loginCount,
        verification: { isVerified },
      } = user;
      const firstLogin =
        loginCount <= 1 && buyerPageVisitsCount >= 2 && !isVerified && !this.isGetFullyVerifiedPopoverAlreadyShown;
      const finalReminder =
        loginCount >= 2 && loginCount <= 4 && !isVerified && !this.isGetFullyVerifiedPopoverAlreadyShown;
      this.showGetFullyVerifiedPopover = firstLogin || finalReminder;
      this.isGetFullyVerifiedPopoverAlreadyShown = true;
    });
  }

  modifyLinksToToggleNav() {
    // apply on medium devices below only - md
    if (this.document.documentElement.offsetWidth >= 768) {
      return;
    }

    this.document.querySelectorAll('a').forEach((el) => {
      // skip elements with no-toggle class
      if (el.className.search('no-toggle') === -1) {
        el.onclick = () => {
          this.isMenuCollapsed = true;
        };
      }
    });
  }

  logout() {
    this.onLogout.emit();
  }

  async gotoEzPaySetup() {
    if (this.user.organizationId) {
      if (this.user.organization.verificationStatus !== OrganizationVerificationStatus.Verified) {
        this.notificationService.notification(
          'warning',
          'Get the business verified first before connecting your bank account',
        );
        return;
      }
      if (!this.user.organization.beneficialOwnersVerifiedAt) {
        this.notificationService.notification(
          'warning',
          'Certify beneficial owners first before connecting your bank account',
        );
        return;
      }
      this.router.navigate(['/business-account/money']);
    } else if (this.isUserVerified) {
      this.router.navigate(['/account/money']);
    } else {
      this.router.navigate(['/account/money/set-up']);
    }
  }

  getCurrentRoute() {
    return this.router.url;
  }

  isUserValid(user) {
    const { userDetails, verification } = user;
    const { address1, city, state, zipcode } = userDetails;
    const { isLicenseVerified, isPhoneVerified, isEmailVerified } = verification;

    const verifyErrors = [
      !isEmailVerified && 'email',
      !isPhoneVerified && 'phone',
      !isLicenseVerified && 'license',
    ].filter(Boolean);

    const accountProfileErrors = [
      !address1 && 'address',
      !city && 'city',
      !state && 'state',
      !zipcode && 'zipcode',
    ].filter(Boolean);

    if (verifyErrors.length || accountProfileErrors.length) {
      const finalizeErrorMgs = (e = [], prefix = '') => {
        if (e.length > 1) {
          e[e.length - 1] = `and ${e[e.length - 1]}`;
        }

        return e.length ? prefix + e.join(', ').replace(/, ([^,]*)$/, ' $1') : '';
      };

      const vErrors = finalizeErrorMgs(verifyErrors, 'Please verify your ');
      const pErrors = finalizeErrorMgs(accountProfileErrors, 'Please provide your ');
      const errorMsgs = [vErrors, pErrors].filter(Boolean).join('. ');
      if (this.accountRecentlyDeleted) {
        this.notificationService.notification(
          'warning',
          `Your ${this.config.appName} Pay account has been closed.
          If you want to re-open you will need to get verified on your profile again.`,
        );
      } else {
        this.notificationService.notification('warning', `${errorMsgs}.`);
        this.router.navigate(['/account/money/set-up']);
      }
    } else {
      this.notificationService.notification(
        'warning',
        `Please set up your ${this.config.appName} Pay account from your profile to access`,
      );
    }
  }

  openBeacon() {
    Beacon('open');
  }

  @Output() scrollEvent = new EventEmitter<number>();

  onScroll(event: any) {
    const scrollPosition = event.target.scrollTop;
    this.scrollEvent.emit(scrollPosition);
  }

  scrollTo(scroll: number) {
    const nativeElement = this.scrollContainer.nativeElement;
    nativeElement.scrollTop = scroll;
  }

  shouldHideNav() {
    return navBarHiddenRoutes.some((e) => window.location.pathname.includes(e));
  }
}
